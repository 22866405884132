import moment from 'moment';

import store from '@/store';

const SeasonalEventModal = () => import('@/components/modals/SeasonalEventModal');

// Making easy to use constants because 0-indexed months are really confusing
export const JANUARY   = 0;
export const MARCH     = 2;
export const JUNE      = 5;
export const SEPTEMBER = 8;
export const DECEMBER  = 11;

export const triggerSeasonalEvent = () => {
    // Use session storage instead of Vuex store so that it expires when session ends
    if (sessionStorage.getItem('seasonalEventSeen'))
        return;

    let now = moment();

    if (now.month() === DECEMBER && now.date() >= 24 || now.month() === JANUARY && now.date() <= 1) {
        store.dispatch('modal/create', {
            component: SeasonalEventModal,
            props: {
                title: "Bonnes fêtes de fin d'année !",
                image: 'christmas.jpg',
                text: 'Mange bien, dors bien, re-mange bien, et à bientôt au local 😊'
            }
        }).then(() => sessionStorage.setItem('seasonalEventSeen', '1'));
    }
};

export const isSpring = () => {
    let now = moment();
    return now.month() > MARCH && now.month() < JUNE ||
        now.month() === MARCH && now.date() >= 20 ||
        now.month() === JUNE && now.date() < 21;
};

export const isSummer = () => {
    let now = moment();
    return now.month() > JUNE && now.month() < SEPTEMBER ||
        now.month() === JUNE && now.date() >= 21 ||
        now.month() === SEPTEMBER && now.date() < 22;
};

export const isAutumn = () => {
    let now = moment();
    return now.month() > SEPTEMBER && now.month() < DECEMBER ||
        now.month() === SEPTEMBER && now.date() >= 22 ||
        now.month() === DECEMBER && now.date() < 21;
};

export const isWinter = () => !isSpring() && !isSummer() && !isAutumn();

export const seasonChecks = {
    isSpring,
    isSummer,
    isAutumn,
    isWinter
};

export default {triggerSeasonalEvent, isSpring, isSummer, isAutumn, isWinter, seasonChecks};