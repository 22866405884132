<template>
    <cover-page-template>
        <div class="text-white">
            <img src="/img/logo.png" alt="Cluji" class="main-logo" /><br />
            <h1 class="m-auto text-white font-weight-lighter">
                Club Jeux et Imaginaire de l'INSA Lyon
            </h1>
            <hr />
            <h2 class="text-white font-weight-lighter">Oyez oyez, nobles joueuses et joueurs !</h2>
            <p class="text-white font-weight-lighter">
                Le Club Jeux et Imaginaire (Cluji) de l'INSA Lyon vous accueille pour des parties de jeu de rôles, de
                cartes, de société ou de plateau mais aussi d'échecs et de go. Tous les joueuses et joueurs venant ou
                non de l'INSA sont les bienvenus&nbsp;! Notre bibliothèque de romans SF et fantasy, nos snacks et la
                réservation du local ou de ses jeux sont également accessibles à tous nos valeureux membres. Le
                Cluji vous reçoit le lundi pour les permanences échecs, le mercredi pour les permanences jeu de société
                ou de cartes (Magic : The Gathering, Yu-gi-oh...) et le vendredi pour les permanences jeu de société.
                Toutes nos permanences sont ouvertes de 20h à 23h59.<br/>

                Hâte de vous croiser prochainement au local 🙂<br/>

                Ludiquement vôtre !
            </p>
        </div>
    </cover-page-template>
</template>

<script>
import season from '@/util/season';

const CoverPageTemplate = () => import('@/components/templates/CoverPageTemplate');

export default {
    name: 'Homepage',
    components: {CoverPageTemplate},
    mounted() {
        season.triggerSeasonalEvent();
    }
};
</script>

<style scoped>
h1 {
    font-size: 40px;
    text-shadow: black 0 0 5px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

p {
    text-shadow: black 0 0 2px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

img.main-logo {
    width: 200px;
    filter: drop-shadow(0 0 0.25rem black);
}

hr {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
}
</style>